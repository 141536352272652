const getInputProps = (props) => {
    const { onChange, disabled, value, state, locked, type, name, checked, baseId, defaultValue, defaultChecked, } = props;
    return {
        onChange,
        disabled,
        value,
        state,
        locked,
        type,
        name,
        checked,
        baseId,
        defaultValue,
        defaultChecked,
    };
};
const getLabelProps = (props) => {
    const { children, baseId, color, icon } = props;
    return { children, baseId, color, icon };
};
const getHTMLProps = (props, exclude) => {
    const htmlProps = {};
    const propKeys = Object.keys(props);
    for (let i = 0; i < propKeys.length; i++) {
        if (!exclude.has(propKeys[i])) {
            htmlProps[propKeys[i]] = props[propKeys[i]];
        }
    }
    return htmlProps;
};
/**
 * A fairly gross way of organizing props so we don't unintentionally pass
 * props to native HTML elements.
 */
export const organizeProps = (props) => {
    const { animation, bigger, plain, shape, fill, iconType, isSwitch, setState, className, ...rest } = props;
    const pcrProps = {
        animation,
        bigger,
        plain,
        shape,
        fill,
        iconType,
        isSwitch,
        setState,
        className,
    };
    const inputProps = getInputProps(rest);
    const labelProps = getLabelProps(rest);
    const htmlProps = getHTMLProps(props, new Set([...Object.keys(pcrProps), ...Object.keys(inputProps), ...Object.keys(labelProps)]));
    return {
        pcrProps,
        inputProps,
        labelProps,
        htmlProps,
    };
};

import * as React from 'react';
import { useIcon } from '../hooks/useIcon';
import { Pretty } from '../factory/Pretty';
import { useUUID } from '../hooks/useUUID';
export const useCheckboxState = ({ state: initialState = false, } = {}) => {
    const [state, setState] = React.useState(initialState);
    return {
        state,
        setState,
        onChange: React.useCallback((e, args) => {
            const value = args || e.currentTarget.value;
            setState(state => {
                if (Array.isArray(state)) {
                    const index = state.indexOf(value);
                    if (index === -1) {
                        state.push(value);
                    }
                    else {
                        state.splice(index, 1);
                    }
                    return state.slice();
                }
                return !state;
            });
        }, []),
        ...useUUID(),
    };
};
export const Checkbox = React.forwardRef(({ value: userValue, icon: userIcon, ...rest }, ref) => {
    const { icon, iconType } = useIcon(userIcon);
    const value = typeof userValue === 'undefined' ? '' : userValue;
    return React.createElement(Pretty, {
        type: 'checkbox',
        icon,
        iconType,
        value,
        ref,
        ...rest,
    });
});
Checkbox.displayName = 'Checkbox';

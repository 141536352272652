import classNames from 'classnames';
export const isBoolean = (value) => typeof value === 'undefined';
export const getChecked = (args) => {
    const { disabled, locked, state, value, type } = args;
    if (disabled || locked) {
        return false;
    }
    if (Array.isArray(state)) {
        return state.indexOf(value) !== -1;
    }
    if (isBoolean(value)) {
        return !!state;
    }
    if (type === 'radio') {
        return state === value;
    }
    return state;
};
export const isDefaultStyle = (animation, icon) => {
    if (animation === 'tada' ||
        animation === 'jelly' ||
        animation === 'rotate' ||
        animation === 'pulse') {
        return false;
    }
    return !icon;
};
export const getClassNames = ({ animation, icon, locked, bigger, plain, shape, fill, className, readOnly, isSwitch, iconType, }) => classNames('pretty', {
    'p-switch': isSwitch,
    'p-default': !isSwitch && isDefaultStyle(animation, icon),
    'p-locked': locked || readOnly,
    'p-bigger': bigger,
    'p-plain': plain,
    [`p-${shape}`]: shape,
    [`p-${fill}`]: fill,
    [`p-${animation}`]: animation,
    [`p-${iconType}`]: iconType,
}, className);

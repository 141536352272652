import * as React from 'react';
import { Pretty } from '../factory/Pretty';
import { useCheckboxState } from './Checkbox';
import { useRadioState } from './Radio';
export const useSwitchState = ({ type = 'checkbox', state: initialState = false, } = {}) => {
    // TODO: might be an issue if the baseId changes if folks change from checkbox > radio
    const checkbox = useCheckboxState(initialState);
    const radio = useRadioState(initialState);
    return Object.assign({ type }, type === 'checkbox' ? checkbox : radio);
};
export const Switch = React.forwardRef(({ value: userValue, ...rest }, ref) => {
    const value = typeof userValue === 'undefined' ? '' : userValue;
    return React.createElement(Pretty, {
        type: 'checkbox',
        isSwitch: true,
        value,
        ref,
        ...rest,
    });
});
Switch.displayName = 'Switch';

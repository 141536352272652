import * as React from 'react';
import classNames from 'classnames';
export const useIcon = (icon) => {
    return React.useMemo(() => {
        if (icon) {
            let type = 'icon';
            if (icon.type === 'img') {
                type = 'image';
            }
            else if (icon.type === 'svg') {
                type = 'svg';
            }
            return {
                iconType: type,
                icon: React.cloneElement(icon, {
                    ...icon.props,
                    className: classNames(icon.props.className, 'icon'),
                }),
            };
        }
        return {};
    }, [icon]);
};

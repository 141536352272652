import * as React from 'react';
import { useIcon } from '../hooks/useIcon';
import { Pretty } from '../factory/Pretty';
import { isBoolean } from '../factory/utils/utils';
import { useUUID } from '../hooks/useUUID';
import { Group } from './Group';
export const useRadioState = ({ state: initialState = false } = {}) => {
    const [state, setState] = React.useState(initialState);
    return {
        state,
        setState,
        onChange: React.useCallback((e, args) => {
            const value = args || e.currentTarget.value;
            setState(prev => (isBoolean(prev) ? !prev : value));
        }, []),
        ...useUUID(),
    };
};
export const RadioGroup = (props) => (React.createElement(Group, Object.assign({ as: "fieldset", role: "radiogroup" }, props)));
export const Radio = React.forwardRef(({ value: userValue, icon: userIcon, shape = 'round', ...rest }, ref) => {
    const { icon, iconType } = useIcon(userIcon);
    const value = typeof userValue === 'undefined' ? '' : userValue;
    return React.createElement(Pretty, {
        type: 'radio',
        shape,
        icon,
        iconType,
        value,
        ref,
        ...rest,
    });
});
Radio.displayName = 'Radio';

import * as React from 'react';
import { getClassNames } from './utils/utils';
import { PrettyInput } from './PrettyInput';
import { PrettyLabel } from './PrettyLabel';
import { organizeProps } from './utils/propUtils';
export const Pretty = React.forwardRef((props, ref) => {
    const { as = 'div', ...rest } = props;
    const { inputProps, labelProps, htmlProps } = organizeProps(rest);
    return React.createElement(as, {
        // @ts-ignore
        className: getClassNames(props),
        'aria-disabled': inputProps.disabled,
        'aria-checked': inputProps.state === 'indeterminate' ? 'mixed' : !!inputProps.state,
        tabIndex: inputProps.locked || inputProps.disabled ? -1 : 0,
        onKeyPress: React.useCallback(
        /* istanbul ignore next */ (e) => {
            /* istanbul ignore next */
            e.preventDefault();
        }, []),
        onKeyUp: React.useCallback((e) => {
            if ((e.keyCode === 32 || e.keyCode === 13) &&
                typeof inputProps.onChange === 'function') {
                inputProps.onChange(e, inputProps.value);
            }
        }, 
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputProps.onChange, inputProps.value]),
        role: inputProps.type,
        ...htmlProps,
    }, React.createElement(React.Fragment, null,
        React.createElement(PrettyInput, Object.assign({ ref: ref }, inputProps)),
        React.createElement(PrettyLabel, Object.assign({}, labelProps))));
});
Pretty.displayName = 'Pretty';
